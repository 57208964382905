import React, { useEffect, useState } from 'react';
import { hive_logo, menu_icon } from 'assets/utils/images';
import { useNavigate } from "components/lib/react-npm";
import CustomButton from 'components/ui/custom-button/CustomButton';
import browserStorageService from 'services/shared/browserStorage.service';
import useAuthenticationStore from 'store/authentication/useAuthenticationStore';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu';
import useSocketSlice from 'store/socket-store/useSocketSlice';
import keys from 'config/keys';
import useStylesStore from 'store/styles/useStylesStore';

export const Header = () => {
    const { setState } = useAuthenticationStore();
    const { common_logo_background_image } = useStylesStore();

    const { socket } = useSocketSlice();
    const slug = browserStorageService.getSession('slug')

    const [isOpen, setIsOpen] = useState(false)

    const navigate = useNavigate();
    useEffect(() => {
        setState({ isScreen: 'PhoneNumberScreen' })
    }, [socket]);
    const logOutHandler = () => {
        if (slug.includes('php')) {
            if (socket) {
                socket.disconnect();
            }
            browserStorageService.removeCookie('_token');
            switch (keys.app.envType) {
                case "development_value":
                    window.open("https://dev.hivebariatrics.com", "_self")
                    break;
                case "med_value":
                    window.open("https://med.hivebariatrics.com", "_self")
                    break;
                case "production_value":
                    window.open("https://www.hivebariatrics.com", "_self")
                    break;

                default:
                    break;
            }
        }
        else {
            if (socket) {
                socket.disconnect();
            }
            useSocketSlice.setState({ socket: null });
            browserStorageService.removeCookie('_token');
            setState({ auth: null });
            navigate(`/${slug}`);
        }

    };

    return (
        <header className="header_main">
            <div className="header_logo">
                <img src={common_logo_background_image || hive_logo} alt="Company Logo" />
            </div>
            <div className='flex flex-col gap-4 pr-5'>
                <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
                    <DropdownMenuTrigger className='outline-none cursor-pointer'>
                        <div className={`${isOpen ? 'change' : ''} header_menu`}>
                            <img src={menu_icon} alt="Menu Icon" />
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="header_menu_content mr-5 w-[200px]">
                        <DropdownMenuItem className='focus:bg-transparent'>
                            <CustomButton value={'Log Out'} onClick={logOutHandler} className="schedule_section-button hive_medical__button p-1" />
                        </DropdownMenuItem>
                        <DropdownMenuItem className='focus:bg-transparent'>
                            <CustomButton value={'Text Us'} className="schedule_section-button hive_medical__button p-1" />
                        </DropdownMenuItem>
                        <DropdownMenuItem className='focus:bg-transparent'>
                            <button className="contact-button">Have a Question? </button>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </header>
    );
};
